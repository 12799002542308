<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 30">
        <path style="fill:#2b2523" d="m 44.83,17.44 h -6.6 a 2.74,2.74 0 0 0 2.71,2.43 3.27,3.27 0 0 0 2.47,-1.13 l 1,1.18 a 4.56,4.56 0 0 1 -3.62,1.65 c -2.69,0 -4.47,-2 -4.47,-4.92 0,-2.92 1.83,-4.92 4.42,-4.94 3.08,0 4.32,2.23 4.09,5.73 z m -1.64,-1.53 a 2.36,2.36 0 0 0 -2.43,-2.52 2.59,2.59 0 0 0 -2.56,2.52 z" />
        <path style="fill:#2b2523" d="m 51.42,8.72 c 2.89,0 4.56,1.58 4.56,4.36 0,2.78 -1.67,4.56 -4.56,4.56 h -2.64 v 3.85 H 46.84 V 8.72 Z m -2.64,7 h 2.56 c 1.78,0 2.78,-0.84 2.78,-2.58 0,-1.74 -1,-2.48 -2.78,-2.48 h -2.56 z" />
        <path style="fill:#2b2523" d="m 62.94,20.31 a 3.39,3.39 0 0 1 -2.89,1.26 2.89,2.89 0 0 1 -3.05,-3 c 0,-1.77 1.2,-2.9 3.3,-2.92 h 2.64 v -0.29 a 1.82,1.82 0 0 0 -2.08,-1.95 4.28,4.28 0 0 0 -2.58,1 l -0.77,-1.46 a 6,6 0 0 1 3.76,-1.22 3.18,3.18 0 0 1 3.53,3.41 v 6.37 h -1.86 z m 0,-2.23 v -0.89 h -2.38 c -1.23,0 -1.81,0.36 -1.81,1.29 0,0.93 0.64,1.44 1.69,1.44 a 2.27,2.27 0 0 0 2.48,-1.84 z" />
        <path style="fill:#2b2523" d="m 69.14,13.72 a 3.14,3.14 0 0 1 3,-2 v 2 a 2.73,2.73 0 0 0 -3,2.7 v 5.07 h -1.89 v -9.71 h 1.89 z" />
        <path style="fill:#2b2523" d="m 78.53,20.31 a 3.38,3.38 0 0 1 -2.89,1.26 2.89,2.89 0 0 1 -3.07,-3 c 0,-1.77 1.2,-2.9 3.3,-2.92 h 2.65 v -0.29 a 1.83,1.83 0 0 0 -2.09,-1.95 4.28,4.28 0 0 0 -2.58,1 l -0.77,-1.46 a 6,6 0 0 1 3.76,-1.22 3.18,3.18 0 0 1 3.53,3.41 v 6.37 h -1.84 z m 0,-2.23 v -0.89 h -2.38 c -1.23,0 -1.81,0.36 -1.81,1.29 0,0.93 0.66,1.44 1.66,1.44 a 2.29,2.29 0 0 0 2.52,-1.84 z" />
        <path style="fill:#2b2523" d="m 84.81,18.77 v 2.72 H 82.92 V 7.95 h 1.89 v 8.18 l 3.86,-4.35 h 2.17 l -3.24,3.79 3.49,6 h -2.24 l -2.56,-4.45 z" />
        <path style="fill:#2b2523" d="m 98.05,12.68 -0.69,1.55 a 4.76,4.76 0 0 0 -2.51,-0.86 c -0.74,0 -1.28,0.27 -1.28,1 0,1.79 4.65,0.82 4.63,4.34 0,2 -1.59,2.89 -3.35,2.89 A 5.08,5.08 0 0 1 91.33,20.24 L 92,18.72 a 4.46,4.46 0 0 0 2.92,1.2 c 0.81,0 1.43,-0.31 1.43,-1 0,-2 -4.6,-0.87 -4.58,-4.38 0,-2 1.54,-2.81 3.22,-2.81 a 5.1,5.1 0 0 1 3.06,0.95 z" />
        <path style="fill:#2b2523" d="m 105,20.87 a 3.86,3.86 0 0 1 -2.15,0.71 c -1.32,0 -2.45,-0.84 -2.45,-2.83 v -5 h -1.26 v -1.6 h 1.23 V 9.48 h 1.87 v 2.67 h 2.58 v 1.57 h -2.58 v 4.65 c 0,1 0.35,1.32 0.94,1.32 a 2.87,2.87 0 0 0 1.32,-0.42 z" />
        <path style="fill:#2b2523" d="m 112.3,12.68 -0.69,1.55 a 4.76,4.76 0 0 0 -2.51,-0.86 c -0.74,0 -1.29,0.27 -1.29,1 0,1.79 4.66,0.82 4.64,4.34 0,2 -1.6,2.89 -3.35,2.89 a 5.06,5.06 0 0 1 -3.52,-1.36 l 0.67,-1.49 a 4.48,4.48 0 0 0 2.93,1.2 c 0.8,0 1.43,-0.31 1.43,-1 0,-2 -4.6,-0.87 -4.59,-4.38 0,-2 1.55,-2.81 3.22,-2.81 a 5.13,5.13 0 0 1 3.06,0.92 z" />
        <rect
            style="fill:#465353"
            x="5.9699998"
            y="11.94"
            width="11.94"
            height="11.94"
        /><rect
            style="fill:#2b383d"
            x="11.94"
            y="11.94"
            width="5.9699998"
            height="5.9699998"
        /><rect
            style="fill:#039aca"
            x="17.92"
            y="11.94"
            width="5.9699998"
            height="5.9699998"
        /><rect
            style="fill:#039aca"
            x="23.889999"
            y="5.9700003"
            width="5.9699998"
            height="5.9699998"
        /><rect
            style="fill:#039aca"
            x="5.9699998"
            y="0"
            width="5.9699998"
            height="5.9699998"
        /><rect
            style="fill:#61c4e0"
            x="17.92"
            y="0"
            width="5.9699998"
            height="11.94"
        /><rect
            style="fill:#ab6ba8"
            x="11.94"
            y="23.889999"
            width="5.9699998"
            height="5.9699998"
        /><rect
            style="fill:#039aca"
            x="11.94"
            y="5.9700003"
            width="5.9699998"
            height="5.9699998"
        /><rect
            style="fill:#465353"
            y="23.889999"
            width="5.9699998"
            height="5.9699998"
            x="0"
        /><rect
            style="fill:#1a1f1d"
            x="11.94"
            y="17.91"
            width="5.9699998"
            height="5.9699998"
        /><rect
            style="fill:#dd5113"
            x="17.92"
            y="17.91"
            width="5.9699998"
            height="5.9699998"
        />
    </svg>
</template>